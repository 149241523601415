import { Component } from '@angular/core';
import { AvailabilityErrorService } from '@/common/availability-error/availability-error.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-availability-error',
	templateUrl: './availability-error.component.html',
	animations: [
		trigger('errorState', [
			state(
				'show',
				style({
					transform: 'translate(-50%,0)',
					opacity: 1,
					visibility: 'visible',
				}),
			),
			state(
				'hide',
				style({
					transform: 'translate(-50%,-100px)',
					opacity: 0,
					visibility: 'hidden',
				}),
			),
			transition('hide => show', animate('500ms ease-in-out')),
			transition('show => hide', animate('500ms ease-in-out')),
		]),
	],
})
export class AvailabilityErrorComponent {
	constructor(public service: AvailabilityErrorService) {}
}
